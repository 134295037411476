import React, { useState } from "react";
import { Button, Divider, Form, Message, Segment } from "semantic-ui-react";
import { Link, useHistory, useLocation } from "react-router-dom";
import request from "./request";
import { useTranslation } from "react-i18next";
import HelpText from "./components/HelpText";
import { usePublicPortalContent } from "./hooks/usePublicPortalContent";
import { toUpper } from "ramda";
import LanguageList from "./LanguageList";
import fatherhood from "./assets/fatherhood.svg";

const STATUS = {
  INIT: 0,
  IN_PROG: 1,
  DONE: 2,
};

function InviteRegistrationScreen() {
  const { t } = useTranslation();

  const { data: contactFooter } = usePublicPortalContent({
    contentModule: "Request Access",
    contentType: "BIGTEXT",
    contentArea: "Registration",
    contentItem: "Contact Us Help Text Footer",
    accessLevel: "Public",
  });

  const [status, setStatus] = useState(STATUS.INIT);
  const [niNassNumber, setNiNassNumber] = useState("");
  const [personBirthdate, setPersonBirthdate] = useState("");
  const [childBirthdate, setChildBirthdate] = useState("");
  const [postcode, setPostcode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [verificationError, setVerificationError] = useState("");
  const location = useLocation();
  const history = useHistory();

  const submitRegistration = () => {
    setStatus(STATUS.IN_PROG);
    const data = {
      email: location.state.email,
      personId: location.state.personId,
      activationKey: location.state.activationKey,
      password: password,
      benefitClaimant: location.state.isBenefitClaimant ? "Y" : "N",
      agreeInd: location.state.agreeInd,
      niNassNumber: niNassNumber,
      birthdate: personBirthdate,
      schoolAgedChildBirthdate: childBirthdate,
      postcode: postcode,
    };
    request
      .post("/api/activation/verify", data)
      .then((response) => {
        if (response.data === true) {
          history.push("/login");
          setVerificationError("");
        } else {
          setVerificationError(
            "The details you have entered cannot be verified"
          );
        }
        setStatus(STATUS.DONE);
      })
      .catch((error) => {
        setStatus(STATUS.DONE);
        setVerificationError(error.response.data.error);
      });
    setStatus(STATUS.DONE);
  };

  return (
    <div className=" flex min-h-screen  ">
      <div className="flex">
        <div className="flex items-center min-h-screen bg-white w-1/3">
          <div className="flex-grow p-16">
            <div className="text-4xl font-bold mb-6">{t("Parents Portal")}</div>
            <div className="flex-grow">
              <div className="text-1xl font-bold ">{t("Welcome!")}</div>
              <br />
              <div className="text-1xl mb-6 font-bold ">
                {t(
                  "To continue your registration, please provide the following information"
                )}
              </div>
            </div>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                submitRegistration();
              }}
            >
              <Message
                verificationError
                hidden={!verificationError}
                onDismiss={() => setVerificationError("")}
              >
                {t(verificationError)}
              </Message>
              {location?.state?.isBenefitClaimant && (
                <Form.Input
                  required
                  icon={"user circle outline"}
                  iconPosition={"left"}
                  label={t(
                    "The National Insurance or NASS Number registered with us"
                  )}
                  value={niNassNumber}
                  placeholder={t("NI or NASS Number")}
                  onChange={(e) => setNiNassNumber(e.target.value)}
                />
              )}
              {location?.state?.isBenefitClaimant && (
                <Form.Input
                  required
                  type="date"
                  icon={"user circle outline"}
                  iconPosition={"left"}
                  label={t("Your Date of Birth")}
                  value={personBirthdate}
                  placeholder={t("Birthdate")}
                  onChange={(e) => setPersonBirthdate(e.target.value)}
                />
              )}
              {!location?.state?.isBenefitClaimant && (
                <Form.Input
                  required
                  icon={"user circle outline"}
                  iconPosition={"left"}
                  label={t("Postcode at your registered address")}
                  value={postcode}
                  placeholder={t("Postcode")}
                  onChange={(e) => setPostcode(toUpper(e.target.value))}
                />
              )}
              {!location?.state?.isBenefitClaimant && (
                <Form.Input
                  required
                  type="date"
                  label={t(
                    "Date of Birth of a School Aged Child Registered with us"
                  )}
                  value={childBirthdate}
                  placeholder={t("Birthdate")}
                  onChange={(e) => setChildBirthdate(e.target.value)}
                />
              )}
              <Form.Input
                required
                label={
                  <>
                    {t("Please Enter a Password")}{" "}
                    <HelpText
                      contentArea="Registration"
                      contentModule="Request Access"
                      contentType="HELP"
                      contentItem="Password Rules Help Text"
                      accessLevel="Public"
                    />
                  </>
                }
                value={password}
                placeholder={t("Add password...")}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <Form.Input
                required
                label={t("Confirm Password")}
                value={confirmPassword}
                placeholder={t("Re-type password...")}
                type="password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  e.target.value && e.target.value !== password
                    ? setPasswordError("Passwords must match")
                    : setPasswordError("");
                }}
              />
              <Message
                passwordError
                hidden={!passwordError}
                onDismiss={() => setPasswordError("")}
              >
                {t(passwordError)}
              </Message>
              <Button
                fluid
                type={"submit"}
                positive
                disabled={status === STATUS.IN_PROG || passwordError}
                loading={status === STATUS.IN_PROG}
              >
                {t("Confirm")}
              </Button>
            </Form>
            <div className="mt-6">
              <Link className="underline" to="/login">
                {t("Return to login screen")}
              </Link>
            </div>
            <div className="mt-6">
              <Divider />
              <LanguageList />
            </div>
            <Segment>
              <div
                dangerouslySetInnerHTML={{
                  __html: contactFooter?.contentValue,
                }}
              />
            </Segment>
          </div>
        </div>
        <div className="flex items-center bg-purple-100 w-2/3 p-16 min-h-screen">
          <img src={fatherhood} alt="" />
        </div>
      </div>
    </div>
  );
}

export default InviteRegistrationScreen;
