import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormField } from "semantic-ui-react";
import { v } from "./utils";

function Input({ label, type = "text", required = false, name, placeholder,path = [], readOnly }) {
  const { control, errors } = useFormContext();

  const hasErrors = !!v(errors, !!path.length ? path : [name], "");

  const errorMessage = v(
    errors,
    !!path.length ? path.concat(["message"]) : [name, "message"],
    ""
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ onChange, value }) => (
        <FormField error={hasErrors} required={required}>
          <label>{label}</label>
          <input
            readOnly={readOnly}
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value)}
          />
          {hasErrors && (
            <span className="text-red-700 text-xs">{errorMessage}</span>
          )}
        </FormField>
      )}
    />
  );
}

export default Input;
