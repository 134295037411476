import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Container,
  Message,
  Icon,
  Form,
  Button,
  Divider,
} from "semantic-ui-react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import request from "./request";
import Input from "./Input";
import fatherhood from "./assets/fatherhood.svg";
import LanguageList from "./LanguageList";
import { useTranslation } from "react-i18next";

const validations = yup.object({
  password: yup.string().required("Required"),
  passwordConfirmation: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("password")], "Passwords must match"),
});

const STATUS = {
  INIT: 0,
  IN_PROG: 1,
  DONE: 2,
};

function ResetScreen() {
  const { t } = useTranslation();
  const methods = useForm({ resolver: yupResolver(validations) });
  const [status, setStatus] = useState(STATUS.INIT);
  const { resetKey } = useParams();

  if (status === STATUS.DONE) {
    return (
      <Container as={Link} to="/login" className="mt-5">
        <Message icon success>
          <Icon name="check" />
          <Message.Content>
            <Message.Header>{t("Account Reset")}</Message.Header>
            {t("Please click here to go back to the login screen.")}
          </Message.Content>
        </Message>
        <Divider />
        <LanguageList />
      </Container>
    );
  }

  return (
    <div className="flex">
      <div className="flex items-center min-h-screen bg-white w-1/3">
        <div className="flex-grow p-16">
          <div className="text-4xl font-bold mb-6">{t("Parents Portal")}</div>
          <FormProvider {...methods}>
            <Form
              onSubmit={methods.handleSubmit(({ password }) => {
                setStatus(STATUS.IN_PROG);
                request
                  .post("/api/reset", { resetKey, password })
                  .then(() => {
                    setStatus(STATUS.DONE);
                  })
                  .catch((e) => {
                    if (
                      e.response.status !== 400 &&
                      e.response.data.field === undefined &&
                      e.response.data.error === undefined
                    ) {
                      setStatus(STATUS.ERROR);
                    } else {
                      setStatus(STATUS.INIT);
                      methods.setError(e.response.data.field, {
                        type: "server",
                        message: e.response.data.error,
                      });
                    }
                  });
              })}
            >
              <Input label={t("New Password")} name="password" type="password" />
              <Input
                label={t("Confirm New Password")}
                name="passwordConfirmation"
                type="password"
              />
              <Button
                positive
                disabled={status === STATUS.IN_PROG}
                loading={status === STATUS.IN_PROG}
              >
                {t("Reset Password")}
              </Button>
              <Button as={Link} to="/login">
                {t("Cancel")}
              </Button>
            </Form>
          </FormProvider>
          <Divider />
          <LanguageList />
        </div>
      </div>
      <div className="flex items-center bg-purple-100 w-2/3 p-16 min-h-screen">
        <img src={fatherhood} alt="" />
      </div>
    </div>
  );
}

export default ResetScreen;
