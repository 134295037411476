import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Icon,
  Message,
  Pagination,
  Popup,
  Segment,
  Table,
} from "semantic-ui-react";
import { useQuery } from "@apollo/react-hooks";
import LoadingMessage from "../../LoadingMessage";
import { gql } from "apollo-boost";
import {
  lookupToOptionsTranslation,
  v,
} from "../../utils";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../Input";
import HelpText from "../../components/HelpText";
import dateFormatter from "../../DateFormatter";
import LookupCode from "../../LookupCode";
import FileDownloadButton from "../../components/FileDownloadButton";

const GET_CASE_NOTES = gql`
  query GetNotes(
    $pupilId: Int!
    $noteType: String
    $noteDate: LocalDate
    $fileName: String
    $noteSubject: String
    $page: Int!
    $size: Int!
  ) {
    lookupCodes(codeTypes: [5035]) {
      codeType
      codeValue
      codeDescription
    }
    notes(
      pupilId: $pupilId
      noteType: $noteType
      noteDate: $noteDate
      fileName: $fileName
      noteSubject: $noteSubject
      page: $page
      size: $size
    ) {
      number
      totalPages
      content {
        ... on NoteDTO {
          noteId
          noteDate
          noteTitle
          noteType
          files {
            id
            filename
            docSize
            mimeType
            application
            blobContent
          }
        }
      }
    }
  }
`;
function CaseNotes({ Dropdowns }) {
  const { t } = useTranslation();
  const searchForm = useForm();
  const { pupilId } = useParams();

  const [pageSize, setPageSize] = useState(10);

  const submitSearch = ({ noteType, noteDate, fileName }) => {
    fetchMore({
      variables: {
        noteDate: noteDate ? noteDate : null,
        fileName: fileName ? fileName : null,
        noteType: noteType ? noteType : null,
        size: pageSize,
        page: 0,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          notes: fetchMoreResult.notes,
        });
      },
    }).then((r) => {});
  };

  const { loading, data, fetchMore } = useQuery(GET_CASE_NOTES, {
    variables: {
      pupilId,
      page: 0,
      size: pageSize,
      noteType: null,
      noteSubject: "PUPIL",
      noteDate: null,
      fileName: null,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (loading) return <LoadingMessage />;

  if (v(data, "notes.content", []).length <= 0)
    return (
      <div>
        <FormProvider {...searchForm}>
          <Form onSubmit={searchForm.handleSubmit(submitSearch)}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Input label={t("Date")} name={"noteDate"} type="date" />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Dropdowns
                    name="noteType"
                    label={t("Note Type")}
                    options={lookupToOptionsTranslation(
                      5035,
                      t
                    )(v(data, "lookupCodes", []))}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Input name="fileName" label={t("File Name")} />
                </Grid.Column>
                <Grid.Column width={4} style={{ marginTop: "5" }}>
                  <br />
                  <Button icon color={"green"} type={"submit"}>
                    <Icon name="search" />
                  </Button>
                  <Popup
                    trigger={
                      <Button
                        icon
                        color={"red"}
                        type={"submit"}
                        onClick={() => {
                          searchForm.reset({
                            noteDate: null,
                            noteType: null,
                            fileName: null,
                          });
                        }}
                      >
                        <Icon name="times circle" />
                      </Button>
                    }
                    content={t("Clear")}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </FormProvider>
        <Message info icon color="purple">
          <Icon name="info" />
          <Message.Header>{t("No records have been found")}</Message.Header>
        </Message>
      </div>
    );

  return (
    <React.Fragment>
      <div>
        <FormProvider {...searchForm}>
          <Form onSubmit={searchForm.handleSubmit(submitSearch)}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Input label={t("Date")} name={"noteDate"} type="date" />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Dropdowns
                    name="noteType"
                    label={t("Note Type")}
                    options={lookupToOptionsTranslation(
                      5035,
                      t
                    )(v(data, "lookupCodes", []))}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Input name="fileName" label={t("File Name")} />
                </Grid.Column>
                <Grid.Column width={4} style={{ marginTop: "5" }}>
                  <br />
                  <Button icon color={"green"} type={"submit"}>
                    <Icon name="search" />
                  </Button>
                  <Popup
                    trigger={
                      <Button
                        icon
                        color={"red"}
                        type={"submit"}
                        onClick={() => {
                          searchForm.reset({
                            noteDate: "",
                            noteType: "",
                            fileName: "",
                          });
                        }}
                      >
                        <Icon name="times circle" />
                      </Button>
                    }
                    content={t("Clear")}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </FormProvider>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("Date")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Note Type")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Description")}</Table.HeaderCell>
              <Table.HeaderCell>{t("File Name")}</Table.HeaderCell>
              <Table.HeaderCell>
                {t("Actions")}
                <HelpText
                  contentArea="Child Record"
                  contentModule="Children"
                  contentType="HELP"
                  contentItem="Case Notes List"
                  accessLevel="Private"
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {v(data, "notes.content", []).map((note) => (
              <Table.Row key={note.noteId}>
                <Table.Cell>{dateFormatter(note.noteDate)}</Table.Cell>
                <Table.Cell>
                  <LookupCode codeType={5035} codeValue={note.noteType} />
                </Table.Cell>
                <Table.Cell>{note.noteTitle}</Table.Cell>
                <Table.Cell>{note?.files?.filename}</Table.Cell>
                <Table.Cell>
                  {note?.files?.id && (
                    <FileDownloadButton fileId={note?.files?.id} />
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Grid>
          <Grid.Row>
            <Grid.Column width={14}>
              <br />
              <br />
              <Pagination
                boundaryRange={1}
                activePage={data.notes.number + 1}
                siblingRange={1}
                totalPages={data.notes.totalPages}
                onPageChange={(e, { activePage }) =>
                  fetchMore({
                    variables: {
                      page: activePage - 1,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      return Object.assign({}, prev, {
                        notes: fetchMoreResult.notes,
                      });
                    },
                  })
                }
              />
            </Grid.Column>
            <Grid.Column width={2} style={{ textAlign: "center" }}>
              <label style={{ fontSize: "0.9rem", color: "#81929B" }}>
                {t("Record per Page")}
              </label>
              <Segment style={{ padding: "10px" }}>
                <Dropdown
                  value={pageSize}
                  name="pageSize"
                  options={[
                    { value: 10, text: "10" },
                    { value: 25, text: "25" },
                    { value: 50, text: "50" },
                    { value: 100, text: "100" },
                  ]}
                  onChange={(_, { value }) => setPageSize(value)}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default CaseNotes;
