import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Message, Icon, Divider, Grid } from "semantic-ui-react";
import request from "./request";
import LanguageList from "./LanguageList";
import { useTranslation } from "react-i18next";
import fatherhood from "./assets/fatherhood.svg";

function ActivationScreen() {
    const { t } = useTranslation();
    const { activationKey } = useParams();
    const [isActivating, setIsActivating] = useState(false);
    const [activatingError, setActivatingError] = useState(false);
    const [activatingSuccess, setActivatingSuccess] = useState(false);

    useEffect(() => {
        const activate = async () => {
            setIsActivating(true);

            try {
                await request.get(`/api/activate/${activationKey}`);
                setActivatingSuccess(true);
            } catch (e) {
                console.error(e);
                setActivatingError(true);
            } finally {
                setIsActivating(false);
            }
        };

        activate();
    }, [activationKey]);

    return (
        <div className="flex">
            <div className="flex items-center min-h-screen bg-white w-1/3">
                <div className="flex-grow p-16">
                    <div className="text-4xl font-bold mb-6">{t("Parents Portal")}</div>
                    {activatingError && (
                        <Grid as={Link} to="/login" className="mt-5">
                            <Message icon error>
                                <Icon name="warning" />
                                <Message.Content>
                                    <Message.Header>{t("Activating Failed")}</Message.Header>
                                    {t("We failed to activate your account.")}
                                </Message.Content>
                            </Message>
                        </Grid>
                    )}
                    {isActivating && (
                        <>
                            <Message icon info>
                                <Icon name="circle notched" loading />
                                <Message.Content>
                                    <Message.Header>{t("Activating Account")}</Message.Header>
                                    {t("Please wait while we activate your account.")}
                                </Message.Content>
                            </Message>
                        </>
                    )}
                    {activatingSuccess && (
                        <Grid as={Link} to="/login" className="mt-5">
                            <Message icon success>
                                <Icon name="check" />
                                <Message.Content>
                                    <Message.Header>{t("Account Activated")}</Message.Header>
                                    {t("Please click here to go back to the login screen.")}
                                </Message.Content>
                            </Message>
                        </Grid>
                    )}
                    <div className="mt-6">
                        <Divider />
                        <LanguageList />
                    </div>
                </div>
            </div>
            <div className="flex items-center bg-purple-100 w-2/3 p-16 min-h-screen">
                <img src={fatherhood} alt="" />
            </div>
        </div>
    );
}

export default ActivationScreen;
