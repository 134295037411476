import {base64ToArrayBuffer, createAndDownloadBlobFile, v} from "../utils";
import {client} from "../App";
import {useTranslation} from "react-i18next";
import {Button} from "semantic-ui-react";
import React, {useState} from "react";
import {FILE_DOWNLOAD} from "../graphql/Queries";
import {useToasts} from "react-toast-notifications";

function FileDownloadButton({ fileId, size }) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [downloading, setDownloading] = useState(false);

  const handleFileDownload = async (fileId) => {
    try {
      setDownloading(true);

      const { data } = await client().query({
        query: FILE_DOWNLOAD,
        variables: { fileId: fileId },
      });

      const fileData = v(data, ["files"], {});

      if (!fileData.base64 || !fileData.filename || !fileData.mimeType) {
        console.error("Incomplete file data received from the server.");
      }

      createAndDownloadBlobFile(
        base64ToArrayBuffer(fileData.base64),
        fileData.filename,
        fileData.mimeType
      );

      setDownloading(false);
    } catch (error) {
      console.error("Error during file download:", error);
      addToast(t("Unable to download the file"), { appearance: "error" });
      setDownloading(false);
    }
  };

  return (
    <Button
      style={{ backgroundColor: "#00AEEF", color: "#fff" }}
      onClick={() => handleFileDownload(fileId)}
      loading={downloading}
      disabled={downloading}
      className={"light-blue"}
      size={size}
    >
      {t("Download")}
    </Button>
  )
}

export default FileDownloadButton;
